/* eslint-disable import/no-unresolved */
import { i18n } from '@lingui/core';
// import {
//   de, en, es, fr, pt, ca,
// } from 'make-plural/plurals';

export const defaultLocale = 'en-US';

// i18n.loadLocaleData('en-US', { plurals: en });
// i18n.loadLocaleData('es-MX', { plurals: es });
// i18n.loadLocaleData('en-GB', { plurals: en });
// i18n.loadLocaleData('es-US', { plurals: es });
// i18n.loadLocaleData('pt-BR', { plurals: pt });
// i18n.loadLocaleData('de-DE', { plurals: de });
// i18n.loadLocaleData('fr-FR', { plurals: fr });
// i18n.loadLocaleData('fr-CA', { plurals: ca });

export const locales = {
  'en-US': 'English (United States)',
  'en-GB': 'English (United Kingdom)',
  'es-MX': 'Español (México)',
  'es-US': 'Español (Estados Unidos)',
  'pt-BR': 'Portugues (brasil)',
  'de-DE': 'Deutsches (Deutschland)',
  'fr-CA': 'Français (Canada)',
  'fr-FR': 'français (France)',
};
async function importMessages(locale) {
  /* eslint-disable import/no-webpack-loader-syntax */
  switch (locale) {
  case 'en-US':
    return import('en-US.js!=!@lingui/loader!../locales/en-US.po');
  case 'en-GB':
    return import('en-GB.js!=!@lingui/loader!../locales/en-GB.po');
  case 'es-MX':
    return import('es-MX.js!=!@lingui/loader!../locales/es-MX.po');
  case 'es-US':
    return import('es-US.js!=!@lingui/loader!../locales/es-US.po');
  case 'pt-BR':
    return import('pt-BR.js!=!@lingui/loader!../locales/pt-BR.po');
  case 'de-DE':
    return import('de-DE.js!=!@lingui/loader!../locales/de-DE.po');
  case 'fr-CA':
    return import('fr-CA.js!=!@lingui/loader!../locales/fr-CA.po');
  case 'fr-FR':
    return import('fr-FR.js!=!@lingui/loader!../locales/fr-FR.po');
  default:
    return import('en-US.js!=!@lingui/loader!../locales/en-US.po');
  }
}

export async function dynamicActivate(locale) {
  const { messages } = await importMessages(locale);
  i18n.load(locale, messages);
  i18n.activate(locale, [locale]);
}

// these are not exported as macros, so wraping them from here for eou
export function date(value, format) {
  return i18n.date(value, format);
}
export function number(value, format) {
  return i18n.number(value, format);
}
